<template>
  <v-card :loading="loading" class="mx-auto" style="height: 100vh;">
    <div class="background-img" :style="{ backgroundImage: `url(${backgroundSrc})` }"></div>

    <div class="controls" v-if="shouldShowParametersButton">
      <button class="param-button" @click="showModelParameters">查看参数</button>
    </div>

    <div v-if="showModelParametersContainer" class="model-parameters-container">
      <div class="close-button" @click="closeModelParameters"></div>
      <img :src="modelInfoSrc"/>
    </div>

    <div id="info" style="height: 100%">
      <v-overlay :value="loading" style="z-index: 99999999">
        <v-progress-circular indeterminate :rotate="90" :size="100" :width="15"></v-progress-circular>
      </v-overlay>
    <!-- <core-footer /> -->
    
      <v-card-actions style=" top: 80%; position: absolute;display: none;">
        <v-spacer></v-spacer>
        <v-icon large @click="previousModel" color="red">
          mdi-chevron-left
        </v-icon>
      </v-card-actions>
      <v-card-actions style="top: 80%; position: absolute; right: 0px;display: none;">
        <v-spacer></v-spacer>
        <v-icon large @click="nextModel" color="red">
          mdi-chevron-right
        </v-icon>
      </v-card-actions>
    </div>
    <div v-if="backgroundSrc=='/static/img/model_background.jpg'" :style="{width:'calc(44%)',right: 'calc(10%)',marginRight: 'auto',marginLeft: 'auto',left:'calc(10%)',paddingTop:'8px',paddingBottom:'8px',top:'70%',position:'absolute'}"><h4>{{currentModelName}}</h4></div>
    <div v-if="backgroundSrc=='/static/img/model_background.jpg'" style="height: auto;margin-bottom:8px;width:calc(49%);left: calc(10%);right: calc(10%);margin-right: auto;margin-left: auto;top:72%;position: absolute;" class="pa-0 px-2 overflow-auto">
      <div class="model-text-justify" v-for="(text,index) in currentModelTextList" :key="index">{{ text }}</div>
    </div>
    <div>
        <span style="
      position: absolute;
      bottom: 0px;
      font-size: 12px;
      left: 3px;
  ">沪ICP备10002431号
      </span>
      <span style="
          bottom: 0px;
          position: absolute;
          right: 3px;
          font-size: 12px;
      ">沪公网安备31010502000766号
      </span>
    </div>
  </v-card>
</template>
<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { MTLLoader } from  'three/examples/jsm/loaders/MTLLoader.js';
import globalConfig from '../../config';
import api from "../../api/api";
export default {
  name: "ThreeModel",
  data: () => ({
    camera: null, 
    scene: null, 
    renderer: null,
    cameraControls: null,
    effectController: null,
    loading: false,
    title: 'test',
    color: '#cdeecc',
    number: 10,
    teapotSize: 300,
    ambientLight: null, 
    light: null,
    lightRelative:null,
    tess: - 1,
    bBottom: null,
    bLid: null,
    bBody: null,
    bFitLid: null,
    bNonBlinn: null,
    shading: null,
    teapot: null,
    textureCube: null,
    object: null,
    materials: {},
    manager: null,
    loader: null,
    mtlLoader:null,
    materialList :null,
    currentModelIndex: 0,
    materialsRefCounter: new Map(),
    currentModelTextList:'',
    canvasHeightRate:0.70,
    currentModelName:"",
    systemTitle:'',
    subSysname:'',
    backgroundSrc:'/static/img/model_background.jpg',
    showModelParametersContainer: false,
    modelInfoSrc: '', // 默认图片的URL
    modelNameCollection: ['AHC-P3', 'AHC-2', "AHC-2-row", "AHC-MS", "AHC-RS", "AHC-D-不带丝杠", "AHC-D-带丝杠", "AHC-D-带丝杠-spindle", "FPG2-PWM", "FPS-PWM", "FPG2-SLP", "FPG2-Hall", "FPG2-2pin+24V","ECA-M"],
    shouldShowParametersButton: false,
    threeModels: [
      {
        modelPath: globalConfig.RESOURCE_PREFIX + "/static/models/PA/0146B0003M.obj",
        modelProperty: {
        },
        modelIntro: ""
      },
      {
        modelPath: globalConfig.RESOURCE_PREFIX + "/static/models/PA/0146B0003M.obj",
        modelProperty: {
        },
        modelIntro: ""
      }
    ]
  }),
  mounted() {
    let subSysname = this.$route.query.subSysname || "";
    let modelName = this.$route.query.name || "";
    this.currentModelName = modelName;
    this.threeModels = api.hanbook.getThreeModelData(subSysname?subSysname:"");
    const modelInfo = api.hanbook.getModelInfoData(subSysname?subSysname:"");
    this.currentModelIndex = this.threeModels.findIndex(function(item, i){
      return item.name === modelName
    });
    this.subSysname = subSysname;
    this.systemTitle = modelInfo.systemTitle;

    const bgSrc = this.threeModels[this.currentModelIndex].imgsrc;
    const miSrc = this.threeModels[this.currentModelIndex].infoPath;
    if(bgSrc && bgSrc !=""){
      this.backgroundSrc = bgSrc;
    }
    if(miSrc && miSrc !=""){
      this.modelInfoSrc = miSrc;
    }
    if (this.modelNameCollection.includes(this.currentModelName)) {
      this.shouldShowParametersButton = true;
    }
    this.init();
    this.loadModel();
    this.render();
  },
  methods: {
    nextModel() {
      if(this.object){
        console.log("rx="+this.object.rotation.x+"   ry="+this.object.rotation.y+"   rz="+this.object.rotation.z);
      }
      // this.loadModel();
      // if (this.currentModelIndex < this.threeModels.length - 1) {
      //   this.currentModelIndex = this.currentModelIndex + 1;
      // } else if (this.currentModelIndex >= this.threeModels.length - 1) {
      //   this.currentModelIndex = 0;
      // }
    },
    loadModel() {
      const modelPath = this.threeModels[this.currentModelIndex].modelPath;
      const modelMaterial = this.threeModels[this.currentModelIndex].modelMaterial
      this.clearObject();
      this.loading = true;
      let scope = this;
      this.currentModelTextList = this.threeModels[this.currentModelIndex].text;

      this.mtlLoader.load( modelMaterial, function ( materials ) {
              scope.materialList = materials;
              scope.loader.setMaterials(materials);
              materials.preload();
					} );
    },
    clearObject() {
      let currObj = this.object;
      let scope = this;
      if (currObj) {
        currObj.traverse(function (item) {
          if (item instanceof THREE.Mesh) {
            item.geometry.dispose();
            scope.removeMaterial(item.material);
          }
        });
      }
      this.scene.remove(currObj);
      this.object = null;
    },
    removeMaterial(material) {
      if (Array.isArray(material)) {
        for (var i = 0, l = material.length; i < l; i++) {
          material[i].dispose();
        }
      } else {
        material.dispose();
      }
    },
    previousModel() {
      if(this.object){
        console.log("rx="+this.object.rotation.x+"   ry="+this.object.rotation.y+"   rz="+this.object.rotation.z);
      }
      // this.loadModel();
      // if (this.currentModelIndex >= 1) {
      //   this.currentModelIndex = this.currentModelIndex - 1;
      // } else if (this.currentModelIndex < 1) {
      //   this.currentModelIndex = this.threeModels.length - 1;
      // }
    },
    init() {
      const container = document.getElementById("info");//.createElement( 'div' );
      //document.body.appendChild( container );

      const canvasWidth = window.innerWidth;
      const canvasHeight = window.innerHeight *this.canvasHeightRate;

      // CAMERA
      this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / canvasHeight, 1, 80000);
      this.camera.fov = 3;
      this.camera.position.set(- 600, 550, 1300);

      // LIGHTS
      let lightColor = 0x404040;//
      let lightIntensity = 1;
      this.ambientLight = new THREE.AmbientLight(0x404040,lightIntensity);

      this.light = new THREE.PointLight(lightColor, lightIntensity); // new THREE.DirectionalLight(lightColor, lightIntensity);//0xffffff 1
      this.light.position.set(100, 100, 100);

      this.lightRelative = new THREE.PointLight(lightColor, lightIntensity);
      this.lightRelative.position.set(-100, -100, -100);

      this.lightTop = new THREE.PointLight(lightColor, lightIntensity);
      this.lightTop.position.set(-100, 100, 100);


      this.lightBottom = new THREE.PointLight(lightColor, lightIntensity);
      this.lightBottom.position.set(100, -100, -100);

      this.lightCorer1 = new THREE.PointLight(lightColor, lightIntensity);
      this.lightCorer1.position.set(-100, 100, -100);

      this.lightCorer2 = new THREE.PointLight(lightColor, lightIntensity);
      this.lightCorer2.position.set(100, -100, 100);

      // RENDERER
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true});
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(canvasWidth, canvasHeight);
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.camera.aspect = canvasWidth / canvasHeight;
      this.camera.updateProjectionMatrix();
      container.appendChild(this.renderer.domElement);

      // EVENTS
      window.addEventListener('resize', this.onWindowResize());

      // CONTROLS
      this.cameraControls = new OrbitControls(this.camera, this.renderer.domElement);
      this.cameraControls.addEventListener('change', this.render);

      // scene itself
      this.scene = new THREE.Scene();
      //this.scene.background = new THREE.Color(0xFFFFFF);

      this.scene.add(this.ambientLight);
      this.scene.add(this.light);
      this.scene.add(this.lightRelative);

      // this.scene.add(this.lightTop);
      // this.scene.add(this.lightBottom);

      this.scene.add(this.lightCorer1);
      this.scene.add(this.lightCorer2);

      // const axesHelper = new THREE.AxesHelper( 50 );
      // this.scene.add( axesHelper );

      // OBJ MODEL
      let scope = this;
      const manager = new THREE.LoadingManager();
      const mtlLoaderManager = new THREE.LoadingManager();
      manager.onLoad = () => {
        
        
        this.object.traverse(function (child) {
        if (child.isMesh) {
            child.frustumCulled = false;
        }
        });

        //

        const modelObj = scope.threeModels[scope.currentModelIndex];
          
          if(modelObj.scale && modelObj.scale.length == 3){
            scope.object.scale.set(modelObj.scale[0],modelObj.scale[1],modelObj.scale[2]);
          }

          if(modelObj.rotate && modelObj.rotate.length == 3){
            if(0 !=modelObj.rotate[0]){
              scope.object.rotateX(modelObj.rotate[0]);
            }

            if(0 !=modelObj.rotate[1]){
              scope.object.rotateY(modelObj.rotate[1]);
            }

            if(0 !=modelObj.rotate[2]){
              scope.object.rotateZ(modelObj.rotate[2]);
            }
          }
        
        // call back after all meshes loaded
        const box = new THREE.Box3().setFromObject(scope.object);
        const size = box.getSize(new THREE.Vector3()).length();
        const center = box.getCenter(new THREE.Vector3());
        scope.object.position.x += (scope.object.position.x - center.x);
        scope.object.position.y += (scope.object.position.y - center.y);
        scope.object.position.z += (scope.object.position.z - center.z);

        

       

        scope.loading = false;
        // // 上下旋转范围
        // this.cameraControls.minPolarAngle = -2 * Math.PI;
        // this.cameraControls.maxPolarAngle = 2* Math.PI;
        // // 左右旋转范围
        // this.cameraControls.minAzimuthAngle = -2 * Math.PI;
        // this.cameraControls.maxAzimuthAngle = 2 * Math.PI;
        this.cameraControls.update();
        scope.scene.add(scope.object);
        scope.render();
      };

      mtlLoaderManager.onLoad = () => {
        // call back after all maetrials loaded
        Object.entries(scope.materialList).forEach(([key, value]) => {
          console.log(`${key} ${value}`);
          //color:0xff0000,
          // 材质像金属的程度. 非金属材料，如木材或石材，使用0.0，金属使用1.0，中间没有（通常）.
          // 默认 0.5. 0.0到1.0之间的值可用于生锈的金属外观
          //metalness: 1.0,
          // 材料的粗糙程度. 0.0表示平滑的镜面反射，1.0表示完全漫反射. 默认 0.5
          //roughness: 0.6,
          if(key == '材质.2' || key == '材质'){
             //value.color = 0xff0000;
             value.metalness = 1.0;
             value.roughness = 1.0;
             value.emissive = value.color;
          }

          if(Object.hasOwn(value, 'side')){
            value.side = THREE.DoubleSide;
          }
        });
 
        scope.loader.load(scope.threeModels[scope.currentModelIndex].modelPath, function (obj) {
            scope.object = obj;
          }, scope.onProgress, scope.onError
        );
      };

      this.manager = manager;
      this.mtlLoader = new MTLLoader(mtlLoaderManager);
      const loader = new OBJLoader(this.manager);
      this.loader = loader;
    },
    render() {
      if (this.shading === 'reflective') {
        this.scene.background = this.textureCube;
      } else {
        this.scene.background = null;
      }

      const canvasWidth = window.innerWidth;
      const canvasHeight = window.innerHeight * this.canvasHeightRate;
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(canvasWidth, canvasHeight);
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.camera.aspect = canvasWidth / canvasHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.render(this.scene, this.camera);
    },
    onWindowResize() {
      let scope = this;
      return function () {
        const canvasWidth = window.innerWidth;
        const canvasHeight = window.innerHeight*this.canvasHeightRate;
        scope.renderer.setPixelRatio(window.devicePixelRatio);
        scope.renderer.outputEncoding = THREE.sRGBEncoding;
        scope.renderer.setSize(canvasWidth, canvasHeight);
        scope.camera.aspect = canvasWidth / canvasHeight;
        scope.camera.updateProjectionMatrix();
        scope.render();
      };
    },
    onProgress(xhr) {
      if (xhr.lengthComputable) {
        //const percentComplete = xhr.loaded / xhr.total * 100;
        //console.log( 'model ' + Math.round( percentComplete, 2 ) + '% downloaded' );
      }
    },
    onError(e) {
      this.loading = false;
      console.log(e);
    },
    showModelParameters() {
      this.showModelParametersContainer = true;
    },
    closeModelParameters() {
      this.showModelParametersContainer = false;
    }
  },
};
</script>

<style lang="sass">
.home
  height: calc(100% - 0px)
#infoGui
  position: absolute
  top: 0px
  right: 0px
  display: block
h4
  font-size: 12px !important
  line-height: 4px !important
  font-family: BoschSans-Light,Helvetica,Arial,sans-serif
  color: #1A1A1A !important
  font-style: normal
div.model-text-justify
  font-size: 12px !important
  line-height: 4px !important
  font-family: BoschSans-Light,Helvetica,Arial,sans-serif !important
  margin-bottom: 16px
  margin-top: 16px
  color: #1A1A1A !important
canvas
  position: absolute
  top: 5%

.background-img
  position: relative
  top: 0
  left: 0
  max-width: 100% !important
  height: 100%
  background-size: 100% 110%
  background-position: 0px 0px !important

.controls
  text-align: center

.param-button
  position: absolute
  padding: 0.5% 1%
  border: 4% solid #a9a9a9
  border-radius: 14%
  cursor: pointer
  background-color: #a9a9a9
  color: #333
  font-size: 3.5vw
  top: 73%
  left: 42%
  z-index: 999

.model-parameters-container 
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  background-color: rgba(255, 255, 255, 0)
  padding: 20px
  display: flex
  align-items: center
  justify-content: center
  z-index: 999999999

  .close-button 
    position: absolute
    top: 20%
    right: 3%
    width: 15%
    height: 8%
    z-index: 999999999999

  img
    max-width: 100%
    max-height: 100%
    margin-bottom: 0px

  ul 
    list-style: none
    padding: 0
    margin: 0
    text-align: left

  li 
    margin-bottom: 0px
</style>
